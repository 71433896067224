<template>
    <!-- 我的留言 -->
    <div class="customer_box">
        <div class="customer_search_box" v-if="pageType===1">
            <div class="search-item">
                <span>关键字</span>
                <el-input style="width: 210px;" clearable placeholder="留言内容、联系人、联系电话" size="small" v-model="filterinfo.keyword"></el-input>
            </div>
            <div class="search-item">

            </div>
            <div class="btn_box">
                <el-button class="btn_item" type="primary" size="small" @click="getMessageList(1)">查询</el-button>
                <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
                <el-button class="btn_item message_btn" size="small" plain @click="buildMsg">新建留言</el-button>
            </div>
        </div>
        <div v-if="pageType===1">
            <div class="table_mode">
                <el-table :data="tableData" v-loading="loading" @sort-change="handleSortChange" stripe style="width: 100%">
                    <el-table-column prop="date" label="创建时间" min-width="100" :show-overflow-tooltip="true" align="center">
                    </el-table-column>
                    <el-table-column prop="msgType" label="留言类型" min-width="70" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="msg" label="留言内容" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="contacter" label="联系人" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="tel" label="联系电话" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <!-- <el-table-column prop="address" label="操作 " width="130" align="center">
                    <template slot-scope="scope">
                        <div class="table_btn">
                            <div class="btn_item" @click="dkxdClick(scope.row.memberId)">下载</div>
                        </div>
                    </template>
                </el-table-column> -->
                    <div slot="empty" class="nodata_box">
                        <img src="@/assets/images/index/nodata.png" />
                        <span class="txt">暂无数据哦~</span>
                    </div>
                </el-table>
            </div>
            <div class="pagination_box">
                <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getMessageList" @size-change="getMessageList(1)">
                </el-pagination>
            </div>
        </div>
        <div style="background:#fff;padding:20px;" v-else>
             <div  class="alert"><i style="color:#ffaa00;margin-right:10px;" class="el-icon-warning"></i><span>以下信息为必填项，属于个人隐私，本站承诺绝不向第三方透漏</span></div>
            <el-form :model="ruleForm" class="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
                <el-form-item label="留言类型" prop="msgType">
                    <el-radio-group v-model="ruleForm.msgType">
                        <el-radio label="订单问题">订单问题</el-radio>
                        <el-radio label="退换货问题">退换货问题</el-radio>
                        <el-radio label="质量问题">质量问题</el-radio>
                        <el-radio label="积分问题">积分问题</el-radio>
                        <el-radio label="客服问题">客服问题</el-radio>
                        <el-radio label="其他">其他</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="联系电话" prop="tel">
                    <el-input v-model="ruleForm.tel" placeholder="请输入您的电话号码，方便我们和您取得联系"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contacter">
                    <el-input v-model="ruleForm.contacter" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="留言内容" prop="msg">
                    <el-input style="width:700px;" v-model="ruleForm.msg" type="textarea" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <div>
                <div style="margin-top:20px;width:700px;display:flex;">
                    <el-button class="back-btn"  @click="goBack">返回</el-button>
                    <el-button class="sure-btn" @click="submitForm">确认提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
    name: 'myMessage',
    data() {
        return {
            loading: false,
            pageType: 1,
            total: 0,
            timeRange: [],
            filterinfo: {
                page: 1,
                pageSize: 10,
                keyword: '',
            },
            tableData: [],
            ruleForm: {
                contacter: '',
                tel: '',
                msg: '',
                msgType: ''

            },
            rules: {
                contacter: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                ],
                msg: [
                    { required: true, message: '请输入留言内容', trigger: 'blur' },

                ],

                tel: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },

                ],
                msgType: [
                    { required: true, message: '请选择留言类型', trigger: 'blur' },
                    {
                        validator: this.validateType, trigger: 'blur'
                    }
                ],
            }
        };
    },
    created() {

    },
    mounted() {
        this.getMessageList()
    },
    methods: {
        buildMsg() {
            this.pageType = 2
        },
        goBack() {
            this.pageType = 1
                this.$refs.ruleForm.resetFields()
        },
        submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    const res = await this.$api.subLeaveMessage(this.ruleForm)
                    if (res.data.code == 200) {
                        this.$message.success('提交成功')
                        this.ruleForm = {}
                        this.pageType = 1
                        this.resetClick()
                    }

                } else {

                    return false;
                }
            });
        },
        validateType(rule, value, callback) {
            console.log(value);
            if (value == '') {
                callback(new Error('请选择留言类型'));
            } else {
                callback();
            }

        },

        // 点击重置
        resetClick() {
            this.filterinfo = {
                page: 1,
                pageSize: 10,
                keyword: '',
            }
            this.getMessageList()
        },
        // 获取我的客户列表
        getMessageList(num) {
            if (num == 1) {
                this.filterinfo.page = 1
            }
            let params = this.filterinfo
            this.loading = true
            this.$api.getLeaveMessage(params).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData = res.data.data.list?res.data.data.list:[]
                    // this.tableData = res.data.data.list
                    // this.options = res.data.data.shopType
                    // this.attributeOptions = res.data.data.attr
                    this.total = res.data.data.total
                }
            });
        },
        handleSortChange({ column, prop, order }) {
            if (order == 'ascending') {
                this.filterinfo.orderBy = "asc"
            } else {
                this.filterinfo.orderBy = "desc"
            }
            this.getMessageList()
        }
    }
};
</script>
<style lang="scss" scoped>
.customer_box {
    position: relative;
    padding-top: 4px;
}
.message_btn {
    border: 1px solid #1a9dff;
    color: #1a9dff;
    &:hover {
        border: 1px solid #337cff;
        color: #337cff;
    }
}
.sure-btn {
    background: #1a9dff;
    color: #fff;
    width: 100%;

    border-radius: 0 !important;

    &:hover {
        background: #337cff;
    }
}
.alert {
    display: flex;
    align-items: center;
    width: 690px;
    // margin-left: 20px;
    // justify-content: center;
    border: 1px solid #fff3c6;
    font-size: 12px;
    background: #fffbe6;
    padding: 5px;
    margin-bottom: 20px;
    color: #ff6600;
}
.back-btn {
    background: #fff;
    color: #000;
    width: 100%;

    border-radius: 0 !important;

    // &:hover {
    //     background: #337cff;
    // }
}
</style>
<style lang="scss">
.ruleForm {
    .el-form-item__content {
        margin: 0 !important;
    }
    .el-form-item {
        margin-bottom: 10px !important;
        display: flex;
        flex-direction: column;
    }
    .el-input {
        width: 700px;
        .el-input__inner {
            border-radius: 0 !important;
        }
    }
}
</style>